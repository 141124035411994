import * as Sentry from '@sentry/sveltekit'
import { dev } from '$app/environment'

if (!dev) {
	Sentry.init({
		dsn: 'https://71cd219fd821f7f956de2cef5453ce7d@o4508341567946752.ingest.de.sentry.io/4508341574565968',

		tracesSampleRate: 1.0
	})
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = Sentry.handleErrorWithSentry()
